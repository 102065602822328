<template>
    <div>
      <h1>Pembayaran Gagal</h1>
      <p>ID Order: {{ orderId }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: ['id'],
    data() {
      return {
        orderId: this.id
      };
    }
  };
  </script>